<template>
  <div class="col-12 g c col-lg-4">
    <br><br>
    <div class="card card-body">
        <img :src="require('@/assets/images/banner.png')" style="width: 50%; margin: 0px auto; padding: 20px" alt="">
        <div class="alert alert-danger text-center g" v-if="error">
            {{ error }}
        </div>
        <div class="form-group g">
          <h5 for="">الجوال / اسم المستخدم</h5>
          <input type="text"
            class="form-control form-control-lg" v-model="phone" v-on:keyup.enter="onEnter" placeholder="اكتب رقم الجوال هنا...">
        </div>
        <div class="form-group g">
          <h5 for="">كلمة المرور (<a href="javascript:;" @click="$router.push('/reset')">نسيت كلمة المرور؟</a>)</h5>
          <input type="password"
            class="form-control form-control-lg" v-model="password" v-on:keyup.enter="onEnter" placeholder="اكتب كلمة المرور هنا...">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="login()">
                <i class="fa fa-check"></i>
                تسجيل الدخول
            </button>
        </div>
        <br>
        <span>
            ليس لديك حساب؟
            <a href="javascript:;" @click="$router.push('/register')">
                <i class="fa fa-user-plus"></i>
                انشاء حساب جديد
            </a>
        </span>
    </div>
    <div class="col-12 text-center g">
        احدى مشاريع شركة <a href="https://brmja.com" target="_blank">برمجة دوت كوم</a>
    </div>
  </div>
</template>

<script>
export default {
    created(){

    },
    data(){
        return {
            phone: "",
            password: "",
            error: null
        }
    },
    methods: {
        onEnter: function() {
            this.login()
        },
        login(){
            var g = this;
            g.error = null;
            $.post(api + '/user/auth/login', {
                phone: g.phone,
                password: g.password
            }).then(function(r){
                if(r.status != 100){
                    g.error = r.response
                }else{
                    localStorage.setItem("user", JSON.stringify(r.response))
                    window.location = '/'
                }
            }).fail(function(){
                g.error = "حدث مشكلة في الاتصال"
            })
        }
    }
}
</script>

<style>

</style>